// src/components/SearchBar.tsx
import React, { useState } from 'react';
import { TextField, Button, Autocomplete, Box, Container, Grid, Typography } from '@mui/material';
import { State } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FILTERS } from '../../store/actions';
import Wallpaper from '../../assets/images/wallpaper.png';
import Topic from '../dashboard/topic';
import SleepImg from '../../assets/images/sleep-2.png';
import PostpartumImg from '../../assets/images/postpartum.png';
import LactationImg from '../../assets/images/lactation.png';
import LaborImg from '../../assets/images/labor.png';
import NutritionImg from '../../assets/images/nutrition.jpeg';
import PregnancyImg from '../../assets/images/pregnancy.jpeg';
import FeedingImg from '../../assets/images/feeding.jpeg';
import LearningImg from '../../assets/images/learning.jpeg';
import { useNavigate } from 'react-router';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

const supportOptions: string[] = [
  'Fertility Support',
  'Birth Doula',
  'Childbirth / Infant Care Education',
  'Lactation / Feeding',
  'Postpartum Doula',
  'Sleep Support',
  'Mental Wellness & Relationships',
  'Child Development & Behavior',
  'Physical Wellness / Perinatal Fitness',
  'Nutrition',
  'I’m not sure'
];

export default function SearchBar() {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const filters = useSelector((state: State) => state.search.filters);
    const [supportType, setSupportType] = useState([]);

    const setFilters = () => {
        if (supportType.length === 1 && supportType[0] == supportOptions[supportOptions.length - 1]) {
            navigate('/match');
        } else {
            dispatcher({
                type: SET_FILTERS,
                payload: {
                    topics: supportType,
                },
            });
        }
    };

    return (
        <Container maxWidth="md">
            <Grid container sx={{ backgroundColor: '#FFFFFF', padding: 2, borderRadius: 2 }}>
                <Grid item xs={12}>
                    <Box mb={2}>
                        <Typography variant="h6">
                            What sort of support are you seeking? (select all that apply)
                        </Typography>
                    </Box>
                </Grid>
                {supportOptions.map((opt) => (
                    <Grid item xs={12} sm={6} md={12}>
                        <Box mb={1}>
                            <Button variant={supportType.includes(opt) ? "contained" : "outlined"} color="primary" onClick={() => {
                                if (supportType.includes(opt)) {
                                    setSupportType(supportType.splice(supportType.indexOf(opt), 1));
                                } else {
                                    setSupportType([...supportType, opt]);
                                }
                            }}>
                                {opt}
                            </Button>
                        </Box>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Box display="flex" alignItems="flex-end" mt={2}>
                        <Button variant="contained" color="secondary" onClick={setFilters}>
                            Search
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={2} sx={{ backgroundColor: '#FFFFFF', padding: 2, pb: 4, borderRadius: 2 }}>
                <Box mb={2} textAlign="center">
                    <Typography variant="h6">
                        Or browse by popular topics:
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={3}>
                        <Topic img={SleepImg} displayText="Sleep" topic="Sleep Support" cancelNavigate />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Topic img={FeedingImg} displayText="Feeding" topic="Lactation / Feeding" cancelNavigate />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Topic img={PregnancyImg} displayText="Pregnancy" topic="Birth Doula" cancelNavigate />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Topic img={LearningImg} displayText="Learning" topic="Child Development & Behavior" cancelNavigate />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} sx={{ backgroundColor: '#FFFFFF', padding: 2, borderRadius: 2 }}>
                <Box mb={2} textAlign="center">
                    <Typography variant="h6">
                        Or get matched by our digital concierge
                    </Typography>
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={2} md={4}></Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/match')}
                            fullWidth
                        >
                            <SupportAgentOutlinedIcon /> <span style={{ marginLeft: 5 }}>Ask the concierge</span>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={2} md={4}></Grid>
                </Grid>
            </Box>
        </Container>
    );
}
