// src/pages/SearchPage.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Container, Grid } from '@mui/material';
import Filters from './filters';
import SearchBar from './search-bar';
import ExpertRow from '../../components/experts/expert-row';
import { State } from '../../types';
import { useLocation, useNavigate, useParams } from 'react-router';
import { NoResults } from '../experts/experts.style';

import { get } from '../../services/api.services';
import { API } from '../../constants';
import { buildSearchQuery } from '../../utils/search';

import {
    SET_PROFILE,
    ADD_EXPERT_PROFILE_ID,
    RESET_EXPERT_PROFILE_IDS,
    SET_FILTERS,
    BUMP_PAGE,
    SET_HAS_NEXT_PAGE,
    RESET_FILTERS,
} from '../../store/actions';

const SearchPage: React.FC = () => {
    const user = useSelector((state: State) => state.account.user);
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const params = useParams();

    const [hasSelectedRole, setHasSelectedRole] = useState(false);
    const token = useSelector((state: State) => state.account.token);
    const expertList = useSelector((state: State) => state.profiles.expertProfileIds);
    const profileMap = useSelector((state: State) => state.profiles.profilesById);
    const filterSet = useSelector((state: State) => state.search.filters);
    const hasNextPage = useSelector((state: State) => state.search.hasNextPage);
    const offset = useSelector((state: State) => state.search.offset);
    const limit = useSelector((state: State) => state.search.limit);

    const [loading, setLoading] = useState(true);

    const fetchExperts = async () => {
        setLoading(true);

        const result = await get(
            `${API.PROFILE}${buildSearchQuery({ ...filterSet, offset, limit })}`,
            token,
        );

        if (offset === 0) {
            dispatcher({
                type: RESET_EXPERT_PROFILE_IDS,
                payload: {},
            });
        }

        if (result.status === 403) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            result.data.experts.forEach((expert) => {
                dispatcher({
                    type: SET_PROFILE,
                    payload: {
                        profile: expert,
                    },
                });

                dispatcher({
                    type: ADD_EXPERT_PROFILE_ID,
                    payload: {
                        expert,
                    },
                });
            });

            if (!result.data.has_next_page) {
                dispatcher({
                    type: SET_HAS_NEXT_PAGE,
                    payload: {
                        hasNextPage: false,
                    },
                });
            }

            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    const fetchNextPage = () => {
        dispatcher({
            type: BUMP_PAGE,
            payload: {},
        });
    };

    useEffect(() => {
        fetchExperts();

        if (filterSet.topics.length > 0) {
            setHasSelectedRole(true);
        }
    }, [filterSet, offset]);

    const expertProfiles = expertList.map((expertId) => profileMap[expertId]);

    return (
        <Container maxWidth="lg" style={!user ? { marginTop: '122px' } : { marginTop: '30px' }}>
            {!hasSelectedRole && (
                <Box>
                    <SearchBar />
                </Box>
            )}
            {hasSelectedRole && (
                <Box>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <Filters />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                        <Box sx={{ padding: 2 }}>
                            {expertProfiles.length === 0 && !loading && (
                                <Box>
                                    <NoResults>
                                        No resources matched your search.
                                    </NoResults>
                                </Box>
                            )}
                            {(!loading || offset > 0) && (
                                <>
                                    <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
                                        <Grid container spacing={2}>
                                            {expertProfiles.map((profile) => (
                                                <ExpertRow
                                                    key={profile.id}
                                                    profile={profile}
                                                />
                                            ))}
                                        </Grid>
                                    </Box>
                                    <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
                                        <Grid container spacing={2}>
                                            {expertProfiles.map((profile) => (
                                                <ExpertRow
                                                    key={profile.id}
                                                    profile={profile}
                                                />
                                            ))}
                                        </Grid>
                                    </Box>
                                </>
                            )}
                            {!loading && hasNextPage && (
                                <Box display="flex" justifyContent="center" mt={2}>
                                    <Button onClick={fetchNextPage} variant="contained" color="primary">Load More</Button>
                                </Box>
                            )}
                            {loading && (
                                <Box sx={{ mt: 4, textAlign: 'center' }}>
                                    <CircularProgress style={{ color: '#5371ff' }} />
                                </Box>
                            )}
                        </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Container>
    );
};

export default SearchPage;
